<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8 my-auto">
            <h1 class="fw-bold text-uppercase text-primary">Marketing Lists</h1>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              class="btn btn-outline-primary btn-sm"
              @click="$router.push('/marketing-suite')"
            >
              <i class="far fa-arrow-left me-2"></i>Marketing
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-8">
            <p class="text-dark">
              Create lists of clients, grouped however you wish, add clients
              manually on the list view or multiple clients at a time with
              filtering from the clients view. You can also quick add a contact
              to a list from their profile.
            </p>
          </div>
        </div>
        <div>
          <div class="row mb-4">
            <div class="col-md-8">
              <div class="card border-0 shadow-none bg-light">
                <div class="card-body">
                  <h4 class="fw-bold text-primary">Create a marketing list</h4>
                  <form @submit.prevent="addNewList">
                    <div class="row mb-2">
                      <div class="col my-auto">
                        <input
                          type="text"
                          v-model="form.name"
                          class="form-control"
                          placeholder="Marketing list name..."
                        />
                      </div>
                      <div class="col-auto my-auto">
                        <button type="submit" class="btn btn-primary">
                          <i class="far fa-save me-2"></i>Create Marketing List
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body p-4">
              <h4 class="fw-bold text-primary mb-4">Current Lists</h4>
              <busy v-if="loading" />
              <div v-else>
                <ul class="list-group list-group-flush" v-if="lists.length">
                  <li
                    class="list-group-item mb-3 shadow-sm border-0 p-3"
                    v-for="l in lists"
                    :key="l.id"
                  >
                    <div class="row">
                      <div class="col my-auto">
                        <h5 class="mb-0 fw-bold text-primary">{{ l.name }}</h5>
                      </div>
                      <div class="col-auto my-auto">
                        <p class="mb-0">
                          <i>{{ l.clients.length }} Clients in list</i>
                        </p>
                      </div>
                      <div class="col-auto my-auto">
                        <button
                          @click="deleteList(l.id)"
                          class="btn btn-outline-danger btn-sm me-3"
                        >
                          <i class="far fa-trash me-2"></i>Delete
                        </button>
                        <button
                          @click="$router.push('/marketing-lists/' + l.id)"
                          class="btn btn-outline-primary btn-sm"
                        >
                          <i class="far fa-eye me-2"></i>View
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>

                <div
                  v-else
                  class="d-flex justify-content-center align-items-center"
                >
                  <div class="text-center">
                    <div>
                      <i class="far fa-search fa-5x text-primary"></i>
                    </div>
                    <p class="fw-bold mt-4">You have no marketing lists yet</p>
                    <p class="fw-bold mt-2">Add one above</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "@/components/Busy";
export default {
  components: { Busy },
  data() {
    return {
      loading: true,
      lists: [],
      form: {
        name: "",
      },
    };
  },
  methods: {
    async addNewList() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL + "/marketing-lists",
        this.form
      );

      this.form = {
        name: "",
      };
      this.fetchLists();
      this.$EventBus.$emit("alert", { message: data.message });
    },
    async fetchLists() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-lists"
      );
      this.lists = data;
      this.loading = false;
    },
    async deleteList(id) {
      const { data } = await this.$axios.delete(
        process.env.VUE_APP_API_URL + "/marketing-lists/" + id
      );
      this.fetchLists();
      this.$EventBus.$emit("alert", { message: data.message });
    },
  },
  created() {
    this.fetchLists();
  },
};
</script>

<style>
</style>
